@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
}


html {
    width: 100%;
    height: 100%;
    overscroll-behavior: none;
    overflow: hidden;
    /* background: #f5f6f8; */
}

body {
    /* background-color: #E5E5E5;
*/
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    width: 100%;
    min-height: 100%;
    margin: 0px;
}

.logo1 {
    width: 3rem;
}

.menu {
    min-height: 100%;
    z-index: 10;
}

.menuHeading {
    font-size: 0.9em;
    margin-top: 2.5px;
}

.menuHeading>input {
    border: none;
    outline: transparent;
}

.menuOption {
    color: black;
}

.menuOption:hover {
    text-decoration: none;
    color: #000;
}

.menuHeading>input> ::placeholder {
    color: black;
}

.active {
    font-weight: 600;
}

.navbar {
    display: flex;
    justify-content: space-between;
}

.logoutButtonContainer {
    margin-right: 1%;
    display: flex;
    direction: row;
    justify-content: center;
    align-items: center;
}

.generateMoreButton {
    font-weight: 400;
    font-size: 14px;
    color: black;
}

.resetSession {
    cursor: pointer;
    font-weight: 400;
    border-radius: 5px;
    font-size: 14px;
    background: transparent;
    border: thin;
    margin-right: 15px;
    color: #4a5965 !important;
    font-family: "Inter", sans-serif;
}

.resetSession:hover {
    background-color: #e3fc0030 !important;
}

.avatar:hover {
    cursor: pointer;
}

.logoutButton {
    /* background-color: #E8FB0A;
	width: 110px;
	border: none;
	cursor:pointer;
	height: 36px;
	font-weight: 550;
	border-radius: 5px;
	 */
    width: 78px;
    cursor: pointer;
    height: 31px;
    font-weight: 400;
    border-radius: 5px;
    font-size: 14px;
    background: transparent;
    border: thin;
    margin-left: 10px;
}

.logoutButton:hover {
    background-color: #e8fb0a;
}

.generateOutlineLink {
    font-size: 0.88vw;
    color: grey;
    border-radius: 5px;
    background: transparent;
    border: none;
    outline: none;
    padding: 10px 8px;
}

.generateOutlineLink:hover {
    color: black;
    background-color: #e8fb0a;
    text-decoration: none;
}

.generateOutlineButton {
    cursor: pointer;
    font-size: 0.88vw;
    color: grey;
    border-radius: 5px;
    background: transparent;
    border: none;
    outline: none;
}

.generateOutlineButton:hover {
    padding-left: 10px;
    padding-right: 10px;
    color: black;
    background-color: #e8fb0a;
}

.generateOutlineButton:focus {
    padding-left: 10px;
    padding-right: 10px;
    color: black;
    background-color: #e8fb0a;
    border: none;
    outline: none;
}

/*START CONTAINERS START*/

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.stack {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


/*END CONTAINERS END*/
.absoluteTopRight {
    position: absolute;
    right: 20px;
    top: 20px;
}

.bulletPointCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;

    border-style: solid;
    border-radius: 10px;
    border-width: 2px;
    border-color: #CDDCEE;


    width: 130px;
    height: 80px;

    font-size: 14px;
    background-color: #fcfcfd;
}

.bulletPointCard p {
    margin: 0px;
    padding: 0px;
}

.subheading {
    font-size: small;
    margin-left: 2px;
    margin-top: -20px;
}

.productDescMenu {
    margin-top: -15px;
}

.alt-text {
    margin-top: -20px;
}

.blog-menu {
    margin-top: -35px;
}

.bulk-menu {
    margin-top: -30px;
}

.alt-text-card>textarea {

    width: 97%;
    height: 97%;
    border: none;


    font-size: 1vw;
    padding: 4px 0 0 0;
    outline: transparent;
    resize: none;

}

.bg-light {
    background-color: white !important;
}

.snap-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    font-feature-settings: "salt" on, "liga" off;
}

.projectNameInput {

    border: none;
    position: absolute;
    width: 175px;
    height: 27px;
    left: 295px;
    top: 18px;
    padding: 0px 9px;
    background: #F5F6F8;
    border-radius: 3px;
    font-size: 0.999vw;
}

.descriptions {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-x: hidden;
}

.interface {
    display: flex;
    height: 100%;
    background: #f5f6f8;
}

.interfaceFeatureTable {
    background: white;
}

/* .socialMediaLogin>button{
font-size: 10px;
background-color: black;
margin-bottom: -10px;
} */
/* .instructionsBar{
	background-color: grey;
	padding: 5px;
} */
.sidebar {
    margin-left: -16px;
    width: 240px;
    background: #ffffff;
    height: 100%;
    box-shadow: 6px 6px 18px rgba(0, 0, 0, 0.06);
    /* border-right: 1px solid #C2CFE0; */
}

.RichTextEditor__root___2QXK- {
    height: 100%;
}

.container-fluid {
    height: 100%;
    padding-right: 0px;
}

.row {
    font-family: "Inter", sans-serif;
    height: 100%;
    /* margin-top: -9px; */
}

.termsPrivacypolicy {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.3rem;
    margin-right: 0%;
    margin-left: 0%;
    color: grey;
    font-size: 10px;
    margin-bottom: -5px;
    font-size: 0.7rem;

}

.terms {
    margin-top: 2px;
}

.playGround {
    /* background-color: ; */
    /* margin-top: -16px; */
    margin-left: -23px;
    width: 100%;
    min-height: 100%;
    overflow-y: scroll;
    background-color: #f5f6f8;
    ;
    overscroll-behavior: none;
    /* padding-bottom: 160px; */
}

.playgroundFeatureTable {
    background-color: white;
}

.tools {
    display: flex;
    padding-top: 2%;
    flex-direction: row;
    justify-content: space-evenly;
}

.content {
    position: absolute;
    width: 100%;
    padding: 7rem;
    margin-left: 5rem;
    height: 100%;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.btn {
    font-size: 0.85rem !important;
    color: black;
    background: #e1fc00;
    padding: 8px 10px;
    border-radius: 8px;
    font-size: 0.89vw;
}

.btn-unselected {
    color: black;
    border: none;
    background: rgb(245, 245, 245);
    padding: 15px 20px;
    border-radius: 8px;
    font-size: 0.89vw;
    /* font-weight: 500; */
    margin-top: 0px;
    cursor: pointer;

}

.upload-btn-wrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.arrow-icon {
    margin-left: 3rem;
    width: 20px;
    margin-bottom: 10px;
}

.header-logo {
    cursor: pointer;
}

.card-header {
    background: #e1fc00;
    padding: 0.8vw 1vw;
    border-radius: 5px 5px 0px 0px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
}

.alt-text-header {
    text-align: center;
    margin-bottom: 2.5%;
    font-size: 1vw;
}

.blogTitle-header {
    text-align: center;
    margin-bottom: 2.5%;
    font-size: 1vw;
    font-family: "Inter", sans-serif;
}

.card-body {
    height: 100%;
    font-size: 0.999vw;
}

.desc-from-image>textarea {
    /* flex: 1; */
    border: none;
    /* min-height: 100px; */
    width: 100%;
    font-size: 1vw;
    padding: 4px 0 0 0;
    outline: transparent;
    resize: none;
}

.tox-tinymce {
    height: 100%;
}

/* .card-body>p{
	max-height: 20px;
} */
.dropdownTags {
    border: 0;
    color: grey;
}

.tagsNumberDesc {
    float: left;
    margin-top: 10px;
    /* margin-left: 22px; */
    color: grey;
    font-size: 0.88vw;
}

.card {
    font-family: "Inter", sans-serif;
    font-size: 1vw;
    width: 100%;
    border-radius: 5px;
    border: 0;
    box-shadow: 6px 10px 18px rgba(0, 0, 0, 0.06);
    margin-top: 2.5%;
    position: initial;
}

.blog-title {
    margin-top: 0;
}

.blog-idea-space {
    margin-top: 0px;
}

.blog-outline-edit>textarea {
    width: 97%;
    height: 97%;
    border: none;


    font-size: 1vw;
    padding: 4px 0 0 0;
    outline: transparent;
    resize: none;
}

.blog-idea>textarea {
    width: 97%;
    height: 97%;
    border: none;
    font-size: 1vw;
    padding: 4px 0 0 0;
    outline: transparent;
    resize: none;
}

.card-spec {
    font-family: "Inter", sans-serif;
    font-size: 1vw;
    width: 100%;
    border-radius: 5px;
    border: 0;
    box-shadow: 6px 10px 18px rgba(0, 0, 0, 0.06);
    height: 100%;
    background: white;
}

.spec-body {
    height: 90%;
    bottom: 10px;
}

.tags-input-spec {
    height: 100%;
}

.tags-input-spec>textarea {
    flex: 1;
    border: none;
    height: 90%;
    width: 100%;
    font-size: 1vw;
    padding: 4px 0 0 0;
    outline: transparent;
    resize: none;
    margin-bottom: 10px;
}

.subSub {
    margin-top: -24px;
    margin-left: 65px;
    font-size: 12px;
}

.blog-idea-box {
    font-family: "Inter", sans-serif;
    font-size: 1vw;
    width: 100%;
    border-radius: 5px;
    border: 0;
    box-shadow: 6px 10px 18px rgba(0, 0, 0, 0.06);
    background: white;
    margin-bottom: 2.5%;
}

.blog-idea {
    padding: 12px 17px;
}

.blog-idea>p {
    margin: 0px;
}

.def-btn {
    background: #f5f6f8;
    height: 2.5rem;
    width: 6.5rem;
    margin-left: 0.5rem;
    border-radius: 6px;
    border: none;
}

.btn-primary1 {
    background: #e1fc00;
    float: right;
}

.card1 {
    height: 100%;
    width: 100%;
    margin-top: -0.1%;
}

.card2 {
    margin-bottom: 2.5%;
}

.card-footer {
    height: fit-content;
    background-color: white;
}

.card-footer-alt {
    height: fit-content;
    background-color: white;
    padding: 6px 15px 7px 4px;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer-blog {
    padding: 5px 11px;
}

.drop-and-tags {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 80vh;
    margin-left: 5%;
    margin-right: 2.5%;
}

.bulk-upload {
    display: flex;
    flex-direction: column;
    width: 85%;
    height: 80vh;
    margin-left: -4%;
    margin-right: 2.5%;
}

/* .bulk-upload-FeatureTable {
    margin-right: %;
} */

.upload-image-btn {
    padding: 20px;


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    border: 1.5px dashed #cccccc;
    border-radius: 10px;
    width: 120px;
    color: #4a5965;
    font-size: 14px;
}

.uploaded-images-wrapper:hover {
    cursor: pointer;
}

.expanded-uploaded-images {
    width: 160px;
    position: absolute;
    left: 0px;
    right: 0px;
    box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.2);
}

.clickable {
    border-style: none;
}

.clickable:hover {
    cursor: pointer;
}

.no-style-button {
    background: red;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
}

.no-style-button:focus {
    outline: none;
}

.uploaded-images {
    width: 80px;
    position: absolute;
    left: 0px;
    right: 0px;
    box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.2);
}

.upload-image-btn:hover {
    cursor: pointer;
}

table {
    background: white;
    z-index: 5;
    position: relative;
}


/* Applies to entire document */
::-webkit-scrollbar {
    display: block !important;
    width: 10px;
    height: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c1c1c1bd;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #9f9f9f;
}



::-webkit-scrollbar-button {
    display: none;
    /* Hide scrollbar buttons */
}

/* Scrollbar styling for textarea in the table  */
textarea::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
}


.tableImage {
    width: 150px;
}

.generateBtnWrapper {
    position: relative;
    margin-top: 0.9rem;
    margin-bottom: 0.7rem;
    width: 98%;
}


.row-disabled {
    position: relative;
}

.row-disabled::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-top: 1px solid black;
}


.btnFeatureTable {
    position: relative;
    left: 100%;
    height: 2rem;
    width: 7rem;
    font-size: 0.7rem;
    padding-top: 5px;
}

tr:nth-child(even) {
    background-color: rgba(243, 243, 243);
}

tr:nth-child(odd) {
    background-color: rgba(255, 255, 255);
}

textarea:focus {
    outline: 1px solid black;
    border-radius: 3px;
}

input:focus {
    outline: 1px solid black;
    border-radius: 3px;
}

tr:hover {
    background-color: rgb(229, 229, 229);
}

th {
    padding-right: 30px;
}

td {
    padding-right: 10px;
}

.downloadCSV {
    padding: 0 20px;
}

.blog-outline {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 80vh;
}

.descriptionSection {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: 2.5%;
    margin-right: 5%;
    min-height: 100%;
}

.altTextSection {
    width: 40%;
    margin-left: 2.5%;
    margin-right: 5%;
    min-height: 100%;
}

.copyButtonIcon {
    cursor: pointer;
    margin-top: 3%;
}

.selectedHighlight {
    box-shadow: 2px 4px 10px 7px rgb(71 185 18 / 20%);

}

.sequenceIcon {}

/**************the tags area*****************/
.appTagSpec {
    padding-top: 4px;
    margin-bottom: -7px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.appTag {
    padding-top: 4px;
    margin-bottom: -7px;
    /* background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between; */
}

/* .suggestions{
    max-height: 127px;
    overflow-y: scroll;
} */
.TagSuggestionsLoading {
    font-size: 0.999vw;
}

.tag-suggestions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 48px;
    width: 100%;
    /* padding: 0 8px; */
    /* border: 1px solid rgb(214, 216, 218);
	border-radius: 6px; */
}

.top-generatecsv-button {
    position: absolute;
    top: 150px;
    right: 30px;
}

.action-buttons-ribbon {
    z-index: 10;
    height: 4rem;
}

.action-buttons {
    display: flex;
    align-items: center;
    gap: 15px;
    position: absolute;
    right: 2rem;
}

.tag-suggestions2 {
    position: relative;
    width: 80%;

}

.suggestion {
    width: auto;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    padding: 1.172vw 0.469vw;
    font-size: 0.999vw;
    list-style: none;
    border-radius: 6px;
    margin: 0 8px 8px 0;
    background: #f0efef;
    cursor: pointer;
    box-shadow: 6px 10px 18px 10px rgb(0 0 0 / 4%);
}

.tags-input {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 48px;
    width: 97%;
    margin-bottom: 10px;
    /* padding: 0 8px; */
    /* border: 1px solid rgb(214, 216, 218);
	border-radius: 6px; */
}

.backToTop img {
    height: 1rem;
    width: 1rem;
    position: relative;
    left: 100%;
    margin-bottom: 1.5rem;
    margin-right: 1.5rem;
}

.backToTop {
    position: relative;
    margin-top: 1.5rem;
    width: 107%;
    display: flex;
    justify-content: flex-end;
}

/* .tags-input>:focus-within{
  border: 1px solid #0052cc;
} */

.tags-input>#tags>input {
    flex: 1;
    border: none;
    height: 30px;
    font-size: 1vw;
    padding: 4px 0 0 0;
    outline: transparent;
}

.tags-input>#tags>input> :focus {
    outline: transparent;
}

#tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0 0;
}

.tagEditArea {
    border: none;
    outline: transparent;
    background: #f5f6f8;
}

.tag {
    width: auto;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    padding: 1.172vw 0.469vw;
    font-size: 0.999vw;
    list-style: none;
    border-radius: 6px;
    margin: 0 8px 8px 0;
    background: #f5f6f8;
}

.tag>tag-title {
    margin-top: 3px;
}

.tag>.tag-close-icon {
    display: block;
    width: 16px;
    height: 14px;
    line-height: 16px;
    text-align: center;
    font-size: 12px;
    margin-left: 2px;
    cursor: pointer;
}

.fa,
.fas {
    font-weight: 600;
    font-size: 0.8vw;
}

/***********************************************/

.btn {
    font-family: "Inter", sans-serif;
}

.App>hr {
    margin-top: -0.6px;
    color: grey;
}

.wordCountArea {
    display: flex;
    justify-content: space-between;
}

.wordCountArea>div {
    font-size: 0.88vw;
    color: grey;
}

.wordCountArea>i {
    font-size: 100px;
}

.copy {
    align-self: flex-end;
}

/* *****************************Image Drop Section********************************** */
.dpc {
    max-width: 90%;
    margin-left: 5%;
    height: 100%;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
    color: #cccccc;
    border: 1.5px dashed #cccccc;
    border-radius: 10px;
}

.drop-zoneFeatureTable {
    color: #9d9a9a;

}

.drop-zone {
    max-width: 90%;
    margin-left: 5%;
    height: 93%;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
    color: #cccccc;
}

.drop-zone--over {
    border-style: solid;
}

.drop-zone__input {
    display: none;
}

#uploaded-image {
    /* width: 85%;
margin-left:41px;
height: 100%; */
    /* border-radius: 10px; */
    /* margin-left: 14%; */
    overflow: hidden;
    /* background-color: #cccccc; */
    /* background-size: contain; */
    /* background-repeat: no-repeat; */
    position: relative;
    /* margin-top: -15px; */
    max-width: 90%;
    height: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    margin: auto;
}

.drop-zone__prompt {
    margin-left: -14px;
}

.drop-zone__thumb {
    /* width: 85%;
margin-left:41px;
height: 100%; */
    /* border-radius: 10px; */
    margin-left: 14%;
    overflow: hidden;
    /* background-color: #cccccc; */
    /* background-size: contain; */
    /* background-repeat: no-repeat; */
    position: relative;
    margin-top: -16px;
    width: 70%;
    height: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
}


.drop-zone__thumb>img {
    align-items: center;
}

.drop-zone__thumb::after {
    content: attr(data-label);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px 0;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.75);
    font-size: 14px;
    text-align: center;
    border: none;
}

/* ********************************************************************** */
#root {
    min-height: 100%;
    /* background: #f5f6f8; */
}

.App {
    height: 100vh;
}

.dropPlatform {
    width: 100%;
    min-height: 47vh;
    background-color: white;
    box-shadow: 6px 10px 18px rgba(0, 0, 0, 0.06);
    padding: 27.5px 0px;
    margin-bottom: 0.5%;
    /* margin-top: -20px; */
}

.steps {
    display: flex;
    justify-content: space-evenly;
}

/* BLOG INTRO PAGE */

.blogHeader {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;

    position: absolute;
    width: 107px;
    height: 40px;

}

.collapseHover {
    position: absolute;
    z-index: 10;
}

.collapseSidebar {
    width: 65px;
    position: absolute;
}

.collapseText {
    visibility: hidden;
}

.collapseIcon {
    width: 100%;
}

.collapsedMenu {
    margin-right: 4%;
}

.newview .plusicon:hover {
    background-color: grey;
}

.newtable .plusicon:hover {
    background-color: rgb(192, 189, 189);
    ;
}

#customTableForm {
    display: flex;
    justify-content: center;
}

@media all and (max-height: 660px) {
    .cornerCredentials {
        display: none;
    }
}

@media only screen and (max-width: 770px) {
    .tag>.tag-close-icon {
        line-height: 10px;
    }
}

@media only screen and (max-width: 1181px) {
    .collapsedMenu {
        margin-right: 7%;
    }

    .tools {
        display: flex;
        flex-direction: column;
    }

    .ipadView {
        justify-content: center;
        display: flex;
        flex-direction: unset;
    }

    .dpc {
        height: 40vh;
    }

    .drop-and-tags {
        margin-left: 11%;
        height: 100%;
        width: 79%;
        margin-bottom: 4%;
    }

    .tag {
        width: auto;
        height: 32px;
        align-items: center;
        justify-content: center;
        color: #000;
        padding: 1vw 0.469vw;
        font-size: 10px;
        list-style: none;
        border-radius: 6px;
        margin: 0 8px 8px 0;
        background: #f5f6f8;
    }

    .btn {
        font-size: 10px;
    }

    .tagsNumberDesc {
        margin-top: 14px;
        font-size: 9px;
        margin-left: 4px;
    }

    .tag>.tag-close-icon {
        display: block;
        width: 16px;
        height: 14px;
        line-height: 16px;
        text-align: center;
        font-size: 12px;
        margin-left: 2px;
        cursor: pointer;
    }

    .descriptionSection {
        margin-left: 11%;
        width: 79%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .card2 {
        margin-top: 4%;
    }

}

.tableheader {
    position: -webkit-sticky;
    position: sticky;
    background-color: white;
    top: -23px;
    z-index: 3;
    box-shadow: 0 -1px #dee2e6, 0 1px #dee2e6;
}

.tablecol1 {
    position: -webkit-sticky;
    position: sticky;
    background-color: inherit;
    left: 0px;
    z-index: 2;
}

.imgcol {
    position: -webkit-sticky;
    position: sticky;
    background-color: inherit;
    left: calc(1vw + 24px);
    z-index: 2;
}

.infoContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 25px;
}

.infoCard {
    height: 250px;
    width: 225px;
    text-align: center;
    padding: 0;
    background-color: white;
    border-radius: 8px;
    border: #000 5px solid;
}

.infoName {
    vertical-align: baseline;
}

.mastertable {
    text-align: center;
    padding: 10px 50px;
    border-radius: 10px;
    margin: 25px auto;
    background-color: #e8fb0a;
    border: none;
    display: block;
    width: 50%
}

.add-batch-button {
    position: fixed;
    bottom: 0;
    right: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: #e1fc00;
    padding: 20px;
    border-radius: 20px 0 0 0;
    box-shadow: -4px -4px 4px lightgrey;
    transition: 0.3s;
}

.add-batch-button:hover {
    box-shadow: none;
}


/* @media only screen and (max-width: 1000px) {
    #generateCSV {
        width: 70%;
    }
} */
@media only screen and (max-width: 600px) {
    .playGround {
        background-color: #f5f6f8;
        margin-top: -16px;
        margin-left: 0px;
        width: 100%;
        min-height: 100%;
        padding-bottom: 12px;
    }

    .tools {
        display: flex;
        flex-direction: column;
    }

    .dropPlatform {
        min-height: fit-content;
        padding-bottom: 15px;
    }

    .drop-and-tags {
        margin-left: 11%;
        min-height: fit-content;
        width: 79%;
        margin-bottom: 5%;
        margin-top: 15px;
    }

    .suggestion {
        font-size: 10px;
        padding: 0px 7px;
    }

    .descriptionSection {
        margin-left: 11%;
        width: 79%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .card {
        font-size: 10px;
    }

    .card2 {
        margin-top: 4%;
    }

    .card-header {
        padding: 8px 8px;
    }

    .card-body {
        font-size: 10px;
        padding: 10px 8px;
        margin-bottom: 0;
    }

    .card-footer {
        padding: 8px 6px;
    }

    .wordCountArea {
        padding-right: 8px;
    }

    .wordCountArea>div {
        font-size: 9px;
    }

    .menu {
        width: 0;
    }

    .sidebar {
        width: 0;
        display: none;
    }

    .TagSuggestionsLoading {
        font-size: 10px;
    }

    .fa,
    .fas {
        font-weight: 600;
        font-size: 9px;
    }
}