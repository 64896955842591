.accordion-section {
    font-family: 'Arial', sans-serif;
    background: #fff;
    border-top: 1px solid #ccc;
  }
  
  .accordion-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    font-size: 18px;
    font-weight: 600;
    background: transparent;
    border: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
  }
  
  .accordion-icon {
    font-size: 24px;
    font-weight: bold;
  }
  
  .accordion-content {
    padding: 20px;
    border-top: 1px solid #ccc;
    display: none; /* initially hide content */
  }
  
  /* Adjust the display property when accordion is open */
  .accordion-section.open .accordion-content {
    display: block;
  }

  
.accordion {
    max-width: 525px; /* Adjust as needed */
    margin: auto;
    font-family: 'Arial', sans-serif;
  }
  
  .accordion-title:hover,
  .accordion-title:focus {
    background: #e9e9e9;
  }
  