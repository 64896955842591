@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
#root{
min-height: 100vh;
}
.lgpge{
  align-self: center;      
  font-family: 'Inter', sans-serif;
  display: grid;
  font-size: 13px;  
  min-height: 100vh;
}
.logoAndImage{
  margin-top: 5%;
  
}

#google{
	font-size: 10px;
	background-color: black;
}
.loginOrSignUpPage{
    display: flex;
    justify-content: space-between;
    
    }
    .loginPage{
      justify-self: end;
    }
    .companyName{
      font-size: 47px;
    margin-left: 2%;
    font-weight: 700;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    }
    .tagLine{
    font-size: 23px;    
    margin-left: 2%;
    font-weight: 500;
    -ms-transform: translateY(-50%);
    transform: translateY(-52%);
    }
    .container {
      width: 313px;   
      padding: 27px 18px;
      /* margin-bottom: 27px; */
      padding-right: 30px;
      margin-right:10%;
      /* margin-top:8%; */
    }
    /* .centeringDivForContainer{
      width: 313px;   
      position: absolute;
    top: 30%;
    } */
    .verticalLine{
      width: 270px;   
      margin-left: -1%;
      color:black;
      margin-bottom: 4%;
    }
    .socialMediaLogin>button{
      border: none;
      background-color: #E8FB0A;
      font-weight: 500;
    }
    /* .logInButtton{
      background-color: #E8FB0A;
      width: 264px;
      border: none;
      cursor:pointer;
      height: 40px;
      font-weight: 550;
      border-radius: 5px;
      margin-bottom:7%;
    } */
    .name{      
      height: 35px; 
      width: 290px;
      font-size: 15px;
      border-radius: 5px;
      border:0;
      box-shadow: rgb(0 0 0 / 50%) 0px 0px 1px;
      color: black;
      padding: 1px 9px;    
      margin-bottom: 2%;  
    }
    /* .email{
      margin: 2% 0 5% 0;
      height: 35px; 
      width: 264px;
      font-size: 12px;
      border-radius: 5px;
      border:0;
      box-shadow: rgb(0 0 0 / 50%) 0px 0px 1px;
      color: black;
      padding: 1px 9px;      
    }
    .password{
      margin-bottom: 5%;
      height: 35px; 
      width: 264px;
      font-size: 12px;
      border-radius: 5px;
      border:0;
      box-shadow: rgb(0 0 0 / 50%) 0px 0px 1px;
      color: black;
      padding: 1px 9px;      
    } */
    .loginLine{
      font-size: 13px;
    }
    h1 {
      margin: 0;
      padding-bottom: 18px;
      font-size: 22px;
      font-weight: 700;
    }
    /* .backToLogin{
      background-color: #E8FB0A;
      padding: 12px 63px;            
      border: none;
      cursor:pointer;      
      font-weight: 550;
      border-radius: 5px;      
      color:black;                  
      margin-bottom: 1%;
    } */
    .backToLogin{
      color:grey;
    }
    
    @media only screen and (max-width: 789px) {
      .loginOrSignUpPage{
        display:block;    
      }
    
      .companyName{
        font-size: 47px;
        margin-left: 3%;
        font-weight: 700;
        top:11%;
      }
      .container{
        width: 313px;
        padding: 27px 30px 27px 18px;    
        margin: auto;
      }
      .centeringDivForContainer{
        top:36%;
      } 
      .tagLine{
        font-size: 20px;
        margin-left: 4%;
        font-weight: 500;
        -webkit-transform: translateY(-52%);
        transform: translateY(-52%);
      }
      .LoginWithId{
            margin-top:-15%;
          }
          .labelForEmail{
            display: none;
          }     
        h1{
          margin-bottom: 21px;
        }
    
      }
        