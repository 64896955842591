@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");


.projectCardTitle {

	position: initial;
	left: 5.03%;
	right: 19.6%;
	top: 5.06%;
	bottom: 78.09%;

	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;

	border-style: none;
	outline: none;
	border-color: none;
	appearance: none;
	background: rgba(0, 0, 0, 0);

	color: #3C557A;

}

.ProjectsInterface {
	background-color: white;

}

.projectCard {
	font-family: "Inter", sans-serif;
	font-size: 13px;
}

.projectCardDetails {
	position: initial;
	left: 5.03%;
	right: 19.6%;
	font-size: 10px;
	font-weight: 400;
	color: #717986;

}

.projectsSubHeader {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 17px;

}

.projectHeading {
	font-family: "Inter", sans-serif;
	font-size: 20px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	color: #1A1F36;

}

.projectsNav {
	font-family: "Inter", sans-serif;
	font-size: 17px;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: left;

	color: #1A1F36;
}

.navigation {
	display: flex;

}

::-webkit-scrollbar {
	display: none
}

.projectInterface {
	background-color: white;
}

.projectInterface {
	background: white;
}

.playGroundProjects {
	background: white;
	margin-left: 20px;
	margin-right: 20px;
	margin-top: 10px;
	overflow-y: scroll;
	overflow-x: hidden;
	position: initial;
}

.showMoreButton {
	text-align: center;

	height: 40px;



	border-radius: 10px;
}

.showMore {
	width: 100px;
	height: 40px;
	left: 798px;
	top: 472px;
	font-family: "Inter", sans-serif;
	font-weight: 500;
	background: #F5F6F8;
	border-radius: 10px;
	border: none;
}

.dataCard {
	max-height: fit-content;
	min-height: 250px;
	width: 250px;
	background: rgba(245, 246, 248, 0.50);
	;
	padding: 0;
	margin-bottom: 25px;
	border-radius: 8px;
	position: relative;
}

.projectImgContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.dataCard img {
	max-width: 100%;
	height: 120px;

	border-radius: 8px;

	margin-left: 31.96%;
	margin-right: 31.96%;
	margin-top: 5.01%;
	margin-bottom: 5.06%;

	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

.data_card_body {
	padding: 8px 20px;
	font-family: Arial, sans-serif;
}

.listings-small {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.listings {
	display: grid;
	grid-template-columns: repeat(4, auto);

}

.input-row {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.input-row label {
	white-space: nowrap;
}

.textarea-wrapper {
	width: 100%;
}

.attribute-textarea {
	width: 100%;
	background: transparent;
	border: none;
	resize: none;
	padding: 1px;
	height: 20px;
}