.product-detail {
    max-width: 500px; /* Adjust the width as needed */
    margin: auto;
    font-family: Arial, sans-serif;
  }
  
  .product-detail h1 {
    font-size: 26px;
    margin-bottom: 8px;
  }
  
  .price {
    font-size: 22px;
    color: #333;
    margin-bottom: 8px;
  }
  
  .sku {
    font-size: 14px;
    color: #666;
    margin-bottom: 16px;
  }
  
  .color-selection, .size-selection {
    margin-bottom: 16px;
  }
  
  .color-selection h2, .size-selection h2 {
    font-size: 16px;
    margin-bottom: 8px;
  }
  
  .color-option {
    padding: 5px;
    margin-right: 8px;
    border: none;
    background-color: #fff;
    cursor: pointer;
  }
  
  .color-image {
    display: inline-block;
    width: 24px; /* Placeholder size */
    height: 24px; /* Placeholder size */
    background-color: #ddd; /* Placeholder color */
    border-radius: 50%;
    line-height: 24px; /* Placeholder for image alignment */
    text-align: center;
    margin-right: 5px;
  }
  
  .sizes {
    margin-bottom: 8px;
  }
  
  .size-option {
    padding: 8px 16px;
    margin-right: 8px;
    border: 1px solid #ddd;
    background-color: #fff;
    cursor: pointer;
  }
  
  .size-option.selected {
    border: 2px solid #333;
  }
  
  .size-guide {
    font-size: 14px;
    text-decoration: none;
    color: #007bff;
    cursor: pointer;
  }
  
  .model-info {
    font-size: 14px;
    color: #666;
    margin-top: 16px;
  }
  